import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from '../components/BackgroundImage'


export default ({ tip, height }) => (


  <Container className={`tip-teaser`}>
    <Link to={`/tips/${tip.slug}`}>

      <BackgroundImage height={height} imageData={tip.image.fluid}>
        <Overlay className="overlay">  
          <div className="overlay-content">
            <ReadTime>{tip.timeToRead} read</ReadTime> 
            <Heading>{tip.title}</Heading>
            <Category>{tip.category.name}</Category>
          </div>    
        </Overlay>
      </BackgroundImage>

      </Link>
  </Container>
)


const Container = styled.div`
 a {
   text-decoration: none;
 }

 section {
    &:hover {
      .overlay {
        background: rgba(0,0,0, 0.5);
      }
    }  
  }
`;

const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.2);
    transition: all 0.5s;
`;

const Heading = styled.h2`
  color: #fff;
  margin-bottom: 0;
`;

const Category = styled.small`
  color: #fff;
  letter-spacing: 0.25em;
`;

const ReadTime = styled.small`
  color: #fff;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  font-size: 14px;
`;